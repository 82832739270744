import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/layout';
import ProductHero from '../../components/product-hero';
import ProductTable from '../../components/product-table';

const ProductTwo = () => {
  const { doorStop } = useStaticQuery(graphql`
    query {
      doorStop: file(relativePath: { eq: "molduras-frames-top-induba.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <div className="max-w-6xl mx-auto my-10 px-4 lg:px-0">
        <ProductHero
          title="Molduras"
          description="Moldura de Madera de pino taeda."
          uses="Moldura de pino para uso de terminación decasas. Por ejemplo: puertas de casa y ventanas."
          isEnglish={false}
        >
          <Img
            className="portrait"
            fluid={doorStop.childImageSharp.fluid}
            fadeIn
          />
        </ProductHero>
        <ProductTable isEnglish={false}>
          <tbody>
            <tr>
              <td className="p-3 align-top" rowSpan="6">
                Molduras
              </td>
              <td className="p-3">1.10 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
            <tr>
              <td className="p-3">1.20 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
            <tr>
              <td className="p-3">1.30 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
            <tr>
              <td className="p-3">1.40 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
            <tr>
              <td className="p-3">1.50 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
            <tr>
              <td className="p-3">2.0 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
          </tbody>
        </ProductTable>
      </div>
    </Layout>
  );
};

export default ProductTwo;
